<template>
  <router-link class="statistic-card" :to="`${path}`">
    <div class="statistic-card-name">{{ name }}</div>
    <div class="statistic-card-number">{{ total }}</div>
  </router-link>
</template>

<script>
export default {
  props: {
    name: String,
    total: Number,
    path: String,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.statistic-card {
  position: relative;
  padding: $mpadding;
  background: $white;
  text-align: center;
  box-shadow: $dshadow;
  width: calc(50% - #{$mpadding});
  border-radius: $mradius/2;
  &-number {
    font-size: 150%;
    font-weight: bold;
    margin-top: 5px;
  }
  &:active {
    background: $white_smoke;
  }
  @media (min-width: $tablet_width) {
    width: calc(33.33% - #{$mpadding});
  }
}
</style>
