<template>
  <div class="page-admin-home" v-bind="getAllData">
    <div class="page-admin-home-statistics" v-if="locale && data">
      <StaticCard v-if="user.roles.includes('super')" :name="locale.words['new']" :total="data.statistics['new']" path="/admin/models/?filter=new" />
      <StaticCard :name="locale.words['approved']" :total="data.statistics['approved']" path="/admin/models/?filter=approved" />
      <StaticCard :name="locale.words['noassistence']" :total="data.statistics['noassistence']" path="/admin/models/?filter=noassistence" />
      <StaticCard
        v-if="user.roles.includes('super')"
        :name="locale.words['disapproved']"
        :total="data.statistics['disapproved']"
        path="/admin/models/?filter=disapproved"
      />
      <StaticCard :name="locale.words['selected']" :total="data.statistics['selected']" path="/admin/models/?filter=selected" />
      <StaticCard :name="locale.words['noselected']" :total="data.statistics['noselected']" path="/admin/models/?filter=noselected" />
      <StaticCard :name="locale.words['admitted']" :total="data.statistics['admitted']" path="/admin/models/?filter=admitted" />
      <StaticCard :name="locale.words['waiting']" :total="data.statistics['waiting']" path="/admin/models/?filter=waiting" />
      <StaticCard :name="locale.words['noadmitted']" :total="data.statistics['noadmitted']" path="/admin/models/?filter=noadmitted" />
      <StaticCard
        v-if="user.roles.includes('super')"
        :name="locale.words['hired']"
        :total="data.statistics['hired']"
        path="/admin/models/?filter=hired"
      />
      <StaticCard
        v-if="user.roles.includes('super')"
        :name="locale.words['deserter']"
        :total="data.statistics['deserter']"
        path="/admin/models/?filter=deserter"
      />
      <StaticCard
        v-if="user.roles.includes('super')"
        :name="locale.words['waiver']"
        :total="data.statistics['waiver']"
        path="/admin/models/?filter=waiver"
      />
      <StaticCard
        v-if="user.roles.includes('super')"
        :name="locale.words['endContract']"
        :total="data.statistics['endContract']"
        path="/admin/models/?filter=endContract"
      />
    </div>
  </div>
</template>

<script>
import store from "../../store/index.js";
import StaticCard from "./AdminStatisticCard";
export default {
  components: { StaticCard },
  props: {
    data: Object,
    user: Object,
  },
  data() {
    return {
      locale: null,
    };
  },
  methods: {
    getdataLang: async function () {
      this.locale = await store.getters["localeLang"];
    },
  },
  computed: {
    getAllData: async function () {
      this.loading = true;
      await this.getdataLang();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.page-admin-home {
  position: relative;
  &-statistics {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 20px;
  }
}
</style>
